define("@heroku/ember-hk-components/components/hk-search-input", ["exports", "@ember/component", "@heroku/ember-hk-components/templates/components/hk-search-input", "@ember/object/internals", "@ember/object"], function (_exports, _component, _hkSearchInput, _internals, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['relative'],
    attributeBindings: ['data-test-target'],
    layout: _hkSearchInput.default,
    /*
    return random by default
    TODO: figure out label requirement for accessibility
    */
    inputId: (0, _object.computed)(function () {
      return (0, _internals.guidFor)({});
    })
  });
});