define("torii/routing/authenticated-route-mixin", ["exports", "rsvp", "@ember/object/mixin", "@ember/object", "@ember/application", "torii/configuration"], function (_exports, _rsvp, _mixin, _object, _application, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins, ember/no-get */
  var _default = _exports.default = _mixin.default.create({
    beforeModel(transition) {
      var route = this;
      var superBefore = this._super.apply(this, arguments);
      if (superBefore && superBefore.then) {
        return superBefore.then(function () {
          return route.authenticate(transition);
        });
      } else {
        return route.authenticate(transition);
      }
    },
    authenticate(transition) {
      var configuration = (0, _configuration.getConfiguration)();
      var route = this,
        session = (0, _application.getOwner)(this).lookup(`service:${configuration.sessionServiceName}`),
        isAuthenticated = (0, _object.get)(session, 'isAuthenticated'),
        hasAttemptedAuth = isAuthenticated !== undefined;
      if (!isAuthenticated) {
        session.attemptedTransition = transition;
        if (hasAttemptedAuth) {
          return route.accessDenied(transition);
        } else {
          return session.fetch().catch(function () {
            return route.accessDenied(session.attemptedTransition);
          });
        }
      } else {
        // no-op; cause the user is already authenticated
        return (0, _rsvp.resolve)();
      }
    },
    accessDenied(transition) {
      transition.send('accessDenied', transition);
    }
  });
});