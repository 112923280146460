define("hk-components/templates/components/hk-modal-flexible", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#ember-wormhole to=this.destinationElementId renderInPlace=this.renderInPlace}}
    <div
      class="{{if this.isClosing "is-closing"}} ember-modal-overlay modal-overlay translucent"
      onclick={{action (ignore-children (action 'closeModalFromOverlay'))}}
      tabindex="-1"
    >
      <EmberModalDialogPositionedContainer
        class="
        ember-modal-dialog
        {{this.containerClassNames}}
        {{this.targetAttachmentClass}}
        {{this.containerClass}}
        {{if this.isClosing "is-closing"}}
          {{if this.isExpanded "is-expanded"}}"
        @targetAttachment={{this.targetAttachment}}
      >
        <div class="modal-box">
          {{#if this.title}}
            <div class="modal-header clearfix" data-test-target="modal-header">
              <MalibuIconButton type="button" class="close" data-test-target="close-button" @name="delete-16" @iconClass="fill-dark-gray" @size={{16}} @title="Close modal" {{action "close"}} />
              <h4 class="modal-title fw5">{{{this.title}}}</h4>
            </div>
          {{/if}}
  
          <div class="modal-content">
            {{yield (hash close=(action "close"))}}
          </div>
        </div>
      </EmberModalDialogPositionedContainer>
    </div>
    <div class="{{if this.isClosing 'is-closing'}} modal-backdrop fixed absolute--fill pe-none"></div>
  {{/ember-wormhole}}
  */
  {
    "id": "/V4FGKKG",
    "block": "[[[6,[39,0],null,[[\"to\",\"renderInPlace\"],[[30,0,[\"destinationElementId\"]],[30,0,[\"renderInPlace\"]]]],[[\"default\"],[[[[1,\"  \"],[10,0],[15,0,[29,[[52,[30,0,[\"isClosing\"]],\"is-closing\"],\" ember-modal-overlay modal-overlay translucent\"]]],[15,\"onclick\",[28,[37,2],[[30,0],[28,[37,3],[[28,[37,2],[[30,0],\"closeModalFromOverlay\"],null]],null]],null]],[14,\"tabindex\",\"-1\"],[12],[1,\"\\n    \"],[8,[39,4],[[16,0,[29,[\"\\n      ember-modal-dialog\\n      \",[30,0,[\"containerClassNames\"]],\"\\n      \",[30,0,[\"targetAttachmentClass\"]],\"\\n      \",[30,0,[\"containerClass\"]],\"\\n      \",[52,[30,0,[\"isClosing\"]],\"is-closing\"],\"\\n        \",[52,[30,0,[\"isExpanded\"]],\"is-expanded\"]]]]],[[\"@targetAttachment\"],[[30,0,[\"targetAttachment\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"modal-box\"],[12],[1,\"\\n\"],[41,[30,0,[\"title\"]],[[[1,\"          \"],[10,0],[14,0,\"modal-header clearfix\"],[12],[1,\"\\n            \"],[8,[39,5],[[24,0,\"close\"],[24,4,\"button\"],[4,[38,2],[[30,0],\"close\"],null]],[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"delete-16\",\"fill-dark-gray\",16,\"Close modal\"]],null],[1,\"\\n            \"],[10,\"h4\"],[14,0,\"modal-title fw5\"],[12],[2,[30,0,[\"title\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[10,0],[14,0,\"modal-content\"],[12],[1,\"\\n          \"],[18,1,[[28,[37,7],null,[[\"close\"],[[28,[37,2],[[30,0],\"close\"],null]]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[52,[30,0,[\"isClosing\"]],\"is-closing\"],\" modal-backdrop fixed absolute--fill pe-none\"]]],[12],[13],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"ember-wormhole\",\"if\",\"action\",\"ignore-children\",\"ember-modal-dialog-positioned-container\",\"malibu-icon-button\",\"yield\",\"hash\"]]",
    "moduleName": "hk-components/templates/components/hk-modal-flexible.hbs",
    "isStrictMode": false
  });
});