define("@heroku/ember-hk-components/templates/components/hk-slide-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "IrrnYeX6",
    "block": "[[[6,[39,0],null,[[\"to\"],[[30,0,[\"destinationId\"]]]],[[\"default\"],[[[[6,[39,1],null,[[\"isOpen\",\"class\",\"clickOverlayToClose\",\"onClick\"],[[30,0,[\"isOpen\"]],[30,0,[\"containerClassNames\"]],[30,0,[\"clickOverlayToClose\"]],[28,[37,2],[[30,0],\"dismiss\"],null]]],[[\"default\"],[[[[1,\"    \"],[11,0],[16,0,[29,[\"hk-slide-panel-container shadow-outer-2 flex flex-column fixed bg-white overflow-y-hidden ma2 br2 \",[30,0,[\"slideDirection\"]],\" \",[30,0,[\"panelDimensions\"]]]]],[24,\"data-test-target\",\"slide-panel-container\"],[4,[38,3],null,[[\"isActive\"],[[30,0,[\"focusTrapIsActive\"]]]]],[12],[1,\"\\n      \"],[18,1,[[28,[37,5],null,[[\"dismiss\",\"header\",\"content\",\"footer\",\"button\",\"closeButton\",\"primaryButton\",\"confirmableAction\"],[[28,[37,2],[[30,0],\"dismiss\"],null],[50,\"hk-slide-panel/header\",0,null,[[\"onDismiss\"],[[28,[37,2],[[30,0],\"dismiss\"],null]]]],[50,\"hk-slide-panel/content\",0,null,null],[50,\"hk-slide-panel/footer\",0,null,null],[50,\"hk-button\",0,null,null],[50,\"hk-button\",0,null,[[\"buttonType\",\"classNames\",\"onClick\",\"default\",\"type\",\"data-test-target\"],[\"secondary\",\"w-50 mr2\",[28,[37,2],[[30,0],\"dismiss\"],null],\"Cancel\",\"button\",\"close-button\"]]],[50,\"hk-button\",0,null,[[\"buttonType\",\"classNames\",\"default\",\"data-test-target\"],[\"primary\",\"w-50\",\"Save\",\"primary-button\"]]],[50,\"hk-slide-panel/confirmable-action\",0,null,[[\"primaryButtonText\",\"secondaryButtonText\"],[\"Confirm\",\"Cancel\"]]]]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]]]]]],[\"&default\"],false,[\"ember-wormhole\",\"hk-slide-panel/overlay\",\"action\",\"focus-trap\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-slide-panel.hbs",
    "isStrictMode": false
  });
});