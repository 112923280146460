define("@heroku/ember-hk-components/templates/components/hk-form-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "nIkbrfAg",
    "block": "[[[18,1,[[28,[37,1],null,[[\"value\",\"label\",\"field\",\"errorsList\"],[[30,0,[\"value\"]],[50,\"hk-label\",0,null,[[\"for\",\"isRequired\",\"data-test-target\"],[[30,0,[\"groupId\"]],[30,0,[\"isRequired\"]],[28,[37,3],[\"form-group-label-\",[30,0,[\"property\"]]],null]]]],[50,[30,0,[\"fieldType\"]],0,null,[[\"id\",\"value\",\"isValid\",\"isInvalid\",\"isValidating\",\"aria-describedby\",\"data-test-target\"],[[30,0,[\"groupId\"]],[30,0,[\"value\"]],[30,0,[\"isValid\"]],[30,0,[\"isInvalid\"]],[30,0,[\"isValidating\"]],[28,[37,3],[[30,0,[\"groupId\"]],\"-errors-list\"],null],[28,[37,3],[\"form-group-field-\",[30,0,[\"property\"]]],null]]]],[50,\"hk-validation-errors-list\",0,null,[[\"validationErrors\",\"property\",\"errorsListId\"],[[30,0,[\"validationErrors\"]],[30,0,[\"property\"]],[30,0,[\"errorsListId\"]]]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"concat\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-form-group.hbs",
    "isStrictMode": false
  });
});