define("ember-svg-jar/inlined/dyno-space-pl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dyno-space-pl</title><g clip-path=\"url(#clip0_225_510)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.499 2.917c.277 0 .501.23.501.509V6.49a.507.507 0 01-.501.509H10.5A.507.507 0 0110 6.491V3.426c0-.281.228-.51.501-.51h.399v-.585C10.9 1.044 11.843 0 13 0c1.16 0 2.1 1.048 2.1 2.33v.587h.399zM9.708 1.846L8.128.985a2.374 2.374 0 00-2.255 0L1.128 3.57C.43 3.951 0 4.654 0 5.414v5.172c0 .76.43 1.463 1.128 1.843l4.745 2.585a2.37 2.37 0 002.255 0l4.745-2.585C13.57 12.05 14 11.346 14 10.586V8H9.668A.664.664 0 019 7.346V2.5c0-.361.304-.654.668-.654h.04zM14 2.32V3h-2v-.68c0-.73.444-1.32 1-1.32.552 0 1 .592 1 1.32z\" fill=\"url(#paint0_linear_225_510)\"/><mask id=\"bvBXfia\" style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"1\" y=\"1\" width=\"12\" height=\"14\"><path d=\"M6.034 1.944a2.035 2.035 0 011.933 0l4.067 2.217c.598.326.966.928.966 1.58v4.433c0 .651-.368 1.253-.966 1.58L7.967 13.97a2.031 2.031 0 01-1.933 0l-4.067-2.216c-.599-.327-.967-.929-.967-1.58V5.74c0-.652.368-1.254.967-1.58l4.067-2.217z\" fill=\"#fff\"/></mask><g mask=\"url(#bvBXfia)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.131 9.86v1.26H5.056v-6h1.526v4.74h2.55z\" fill=\"url(#paint1_linear_225_510)\"/></g></g><defs><linearGradient id=\"paint0_linear_225_510\" x1=\"-8\" y1=\"7.65\" x2=\"7.285\" y2=\"23.634\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8EBDF1\"/><stop offset=\"1\" stop-color=\"#006DEB\"/></linearGradient><linearGradient id=\"paint1_linear_225_510\" x1=\"3.019\" y1=\"8.12\" x2=\"8.596\" y2=\"11.908\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#fff\"/><stop offset=\"1\" stop-color=\"#fff\"/></linearGradient><clipPath id=\"clip0_225_510\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});