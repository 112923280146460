define("ember-svg-jar/inlined/dyno-1x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dyno-1x</title><g clip-path=\"url(#clip0_69_1679)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.873.985a2.374 2.374 0 012.255 0l4.745 2.586C14.57 3.951 15 4.654 15 5.414v5.172c0 .76-.43 1.463-1.127 1.843l-4.745 2.585a2.37 2.37 0 01-2.255 0L2.128 12.43C1.43 12.05 1 11.346 1 10.586V5.414c0-.76.43-1.462 1.128-1.843L6.873.985z\" fill=\"#F0EEF4\" stroke=\"#8060A6\"/><path d=\"M7.47 4.525v6.545H5.691V6.16h-.038l-1.432.857V5.51l1.611-.984h1.636z\" fill=\"url(#paint0_linear_69_1679)\"/><path d=\"M9.027 9.713l-.634-.634 3.097-3.151.634.646-3.097 3.139zm2.47.024l-3.074-3.17.634-.657 3.08 3.169-.64.658z\" fill=\"url(#paint1_linear_69_1679)\"/></g><defs><linearGradient id=\"paint0_linear_69_1679\" x1=\"1.326\" y1=\"7.57\" x2=\"9.614\" y2=\"11.337\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#AB8ECD\"/><stop offset=\"1\" stop-color=\"#79589F\"/></linearGradient><linearGradient id=\"paint1_linear_69_1679\" x1=\"6.522\" y1=\"7.823\" x2=\"10.348\" y2=\"11.565\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#AB8ECD\"/><stop offset=\"1\" stop-color=\"#79589F\"/></linearGradient><clipPath id=\"clip0_69_1679\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});