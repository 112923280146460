define("torii/components/torii-iframe-placeholder", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-components, ember/no-classic-classes, ember/require-tagless-components */
  var _default = _exports.default = _component.default.extend({
    classNames: ['torii-iframe-placeholder']
  });
});