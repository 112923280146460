define("ember-svg-jar/inlined/fir", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.568 35.167h.5v-5.743l-.354-.108L.677 26.249 11.234 1.284l10.558 24.965-10.037 3.067-.354.108v5.743h1.864v.333H9.204v-.333h1.364z\" fill=\"#fff\" stroke=\"#592E8B\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "23",
      "height": "36",
      "viewBox": "0 0 23 36",
      "fill": "none"
    }
  };
});