define("ember-svg-jar/inlined/region-private", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><linearGradient id=\"kDxQjXa\" x1=\"0%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#64B5F3\"/><stop offset=\"100%\" stop-color=\"#347EE2\"/></linearGradient><linearGradient id=\"kDxQjXb\" x1=\"1.535%\" y1=\"1.535%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#F9FBFF\"/><stop offset=\"100%\" stop-color=\"#EEF1F6\"/></linearGradient></defs><g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"20\" height=\"14\" fill=\"url(#kDxQjXa)\" rx=\"2\"/><path fill=\"url(#kDxQjXb)\" d=\"M12.518 6c.277 0 .502.264.502.582l-.015 3.836c0 .322-.228.582-.502.582H7.502C7.225 11 7 10.736 7 10.418l.015-3.836c0-.322.228-.582.501-.582h.4v-.47c0-1.47.928-2.53 2.086-2.53 1.161 0 2.117 1.064 2.117 2.53V6h.4zm-1.3 0v-.64c0-.897-.552-1.493-1.216-1.493-.667 0-1.186.595-1.186 1.494V6h2.402z\"/><path fill=\"#000\" fill-opacity=\".15\" fill-rule=\"nonzero\" d=\"M2.994.75A2.247 2.247 0 00.75 3.003v7.994a2.25 2.25 0 002.244 2.253h14.012a2.247 2.247 0 002.244-2.253V3.003A2.25 2.25 0 0017.006.75H2.994zm0-.75h14.012A3 3 0 0120 3.003v7.994A2.997 2.997 0 0117.006 14H2.994A3 3 0 010 10.997V3.003A2.997 2.997 0 012.994 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "14",
      "viewBox": "0 0 20 14"
    }
  };
});