define("@heroku/ember-hk-components/components/hk-input-with-validation-status", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@heroku/ember-hk-components/templates/components/hk-input-with-validation-status"], function (_exports, _component, _object, _computed, _hkInputWithValidationStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _hkInputWithValidationStatus.default,
    tagName: '',
    supportsDataTestProperties: true,
    isFieldChanged: false,
    inputValue: (0, _computed.oneWay)('value'),
    isFieldNotEmpty: (0, _computed.notEmpty)('value'),
    isShowingStatus: (0, _computed.and)('isFieldNotEmpty', 'isFieldChanged'),
    inputClasses: (0, _object.computed)('isValid', 'isValidating', 'isShowingStatus', function () {
      let classes = this.get('class') || '';
      if (this.get('isShowingStatus')) {
        classes += ' br--left z-1';
      }
      return classes;
    }),
    actions: {
      onChange(newValue) {
        // Some keys (arrow, shift, etc) don't change the value, and we only want
        // this to fire when the field has changed.
        if (newValue !== this.get('value')) {
          this.set('value', newValue);
          this.set('isFieldChanged', true);
        }
      }
    }
  });
});