define("torii/lib/assert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = assert;
  function assert(message, test) {
    if (!test) {
      console.error(message); // eslint-disable-line
    }
  }
});