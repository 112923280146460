define("torii/lib/parse-query-string", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes */
  var _default = _exports.default = _object.default.extend({
    init() {
      this.validKeys = this.keys;
    },
    parse() {
      var url = this.url,
        validKeys = this.validKeys,
        data = {};
      for (var i = 0; i < validKeys.length; i++) {
        var key = validKeys[i],
          regex = new RegExp(key + '=([^&#]*)'),
          match = regex.exec(url);
        if (match) {
          data[key] = match[1];
        }
      }
      return data;
    }
  });
});