define("@heroku/ember-hk-components/components/hk-button", ["exports", "@ember/object", "@ember/object/computed", "rsvp", "@ember/runloop", "@heroku/ember-hk-components/components/async-button", "@heroku/ember-hk-components/templates/components/hk-button", "dashboard/utils/overridable-computed"], function (_exports, _object, _computed, _rsvp, _runloop, _asyncButton, _hkButton, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _asyncButton.default.extend({
    layout: _hkButton.default,
    buttonPrefix: 'hk-button',
    attributeBindings: ['disabled', 'form', 'title'],
    classNameBindings: ['buttonClass'],
    ...(0, _overridableComputed.default)('isPending', (0, _computed.equal)('textState', 'pending')),
    ...(0, _overridableComputed.default)('buttonClass', (0, _object.computed)('buttonType', 'buttonSize', 'disabled', function () {
      const buttonPrefix = this.get('buttonPrefix');
      const isDisabled = this.get('disabled');
      const buttonType = this.get('buttonType') || 'primary';
      const buttonSize = this.get('buttonSize');
      if (isDisabled) {
        return `${buttonPrefix}${buttonSize}--disabled-${buttonType}`;
      } else {
        return `${buttonPrefix}${buttonSize}--${buttonType}`;
      }
    })),
    isSmallButton: (0, _computed.equal)('size', 'small'),
    buttonSize: (0, _object.computed)('size', function () {
      switch (this.get('size')) {
        case 'small':
          return '-sm';
        default:
          return '';
      }
    }),
    click() {
      const actionResult = _rsvp.default.resolve(this.get('onClick')());
      const timeout = new _rsvp.default.Promise(resolve => {
        (0, _runloop.later)(function () {
          resolve('__timeout__');
        });
      });

      // see if our action or our placeholer timeout finishes first
      const promise = _rsvp.default.race([actionResult, timeout]).then(value => {
        if (value !== '__timeout__') {
          // action finished first
          // probably a sync thing wrapped with Ember.RSVP.resolve,
          // don't show the loading state
        } else {
          // timeout finished first
          // we probably have an actually async thing like XHR
          // show the loading state

          if (!this.isDestroyed) {
            this.set('textState', 'pending');
          }
          // keep the spinner going until the action's promise
          // resolves or rejects
          return actionResult;
        }
      });
      this.set('promise', promise);
      return false;
    }
  });
});