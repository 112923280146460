define("ember-svg-jar/inlined/dyno-pl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dyno-pl</title><g clip-path=\"url(#clip0_69_964)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.873 1.485a2.374 2.374 0 012.255 0l4.745 2.586C14.57 4.451 15 5.154 15 5.914v5.172c0 .76-.43 1.463-1.127 1.843l-4.745 2.585a2.37 2.37 0 01-2.255 0L2.128 12.93C1.43 12.55 1 11.846 1 11.086V5.914c0-.76.43-1.462 1.128-1.843l4.745-2.586z\" fill=\"url(#paint0_linear_69_964)\"/><path d=\"M6.235 11.41V5.592h1.58v4.546h2.352v1.272H6.235z\" fill=\"#fff\"/></g><defs><linearGradient id=\"paint0_linear_69_964\" x1=\"-6\" y1=\"8.5\" x2=\"8.587\" y2=\"22.488\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#AB8ECD\"/><stop offset=\"1\" stop-color=\"#79589F\"/></linearGradient><clipPath id=\"clip0_69_964\"><path fill=\"#fff\" transform=\"translate(0 .5)\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "17",
      "viewBox": "0 0 16 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});