define("ember-svg-jar/inlined/cc-visa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><linearGradient id=\"hmurwia\" x1=\"50%\" x2=\"50%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#FFF\"/><stop offset=\"100%\" stop-color=\"#ECEEF8\"/></linearGradient><linearGradient id=\"hmurwib\" x1=\"0%\" x2=\"66.866%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#191E62\"/><stop offset=\"100%\" stop-color=\"#10289A\"/></linearGradient></defs><g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"40\" height=\"26\" fill=\"url(#hmurwia)\" rx=\"4\"/><path fill=\"#000\" fill-opacity=\".101\" fill-rule=\"nonzero\" d=\"M4 .75A3.249 3.249 0 00.75 4.006v17.988A3.256 3.256 0 004 25.25h32a3.249 3.249 0 003.25-3.256V4.006A3.256 3.256 0 0036 .75H4zM4 0h32c2.204 0 4 1.793 4 4.006v17.988A3.999 3.999 0 0136 26H4c-2.204 0-4-1.793-4-4.006V4.006A3.999 3.999 0 014 0z\"/><path fill=\"url(#hmurwib)\" d=\"M20.015 11.197C20.03 9.315 21.77 8 24.243 8c.964 0 1.739.21 2.341.43l-.44 2.05c-1.12-.516-2.109-.48-2.467-.432-.723.094-1.053.463-1.062.809-.024 1.14 3.517 1.282 3.507 3.834-.01 2.012-1.729 3.309-4.357 3.309-1.12-.01-2.199-.245-2.782-.513l.453-2.12c.581.272 1.312.631 2.57.611.72-.01 1.49-.297 1.497-.946.004-.424-.323-.728-1.298-1.203-.948-.462-2.207-1.24-2.19-2.632zm10.795-3.02h2.137l2.02 9.672h-2.314l-.304-1.444H29.14l-.522 1.444h-2.626l3.754-8.963c.18-.43.585-.71 1.064-.71zm.368 2.613l-1.318 3.632h2.076l-.758-3.632zM16.832 8.177h2.5l-2.068 9.672h-2.502l2.07-9.672zm-3.702 0h2.627l-4.054 9.672H9.06L7.063 10.13c-.12-.475-.226-.65-.594-.85-.602-.327-1.595-.633-2.469-.822l.06-.281h4.257c.543 0 1.03.36 1.154.985l1.054 5.598 2.605-6.583z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "40",
      "height": "26",
      "viewBox": "0 0 40 26"
    }
  };
});