define("@heroku/ember-hk-components/components/hk-input", ["exports", "@ember/legacy-built-in-components", "@ember/runloop"], function (_exports, _legacyBuiltInComponents, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _legacyBuiltInComponents.TextField.extend({
    attributeBindings: ['aria-describedby', 'data-test-target'],
    classNameBindings: ['isValid:b--light-green', 'isValid:hk-focus-ring--green', 'isInvalid:b--light-red', 'isInvalid:hk-focus-ring--red', 'disabled:hk-input--disabled', 'readonly:hk-input--read-only'],
    classNames: ['hk-input'],
    didInsertElement() {
      this._super(...arguments);
      (0, _runloop.scheduleOnce)('afterRender', this, this._setAutoFocus);
    },
    _setAutoFocus() {
      if (this.get('autofocus')) {
        this.element.focus();
      }
    }
  });
});