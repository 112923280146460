define("ember-svg-jar/inlined/hex-app-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><linearGradient id=\"ktzVBqa\" x1=\"50%\" x2=\"50%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#FCFAFF\"/><stop offset=\"100%\" stop-color=\"#F2E7FF\"/></linearGradient><linearGradient id=\"ktzVBqb\" x1=\"0%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#AB8ECD\"/><stop offset=\"100%\" stop-color=\"#79589F\"/></linearGradient></defs><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"url(#ktzVBqa)\" fill-rule=\"nonzero\" d=\"M15.927 1.793a6.356 6.356 0 016.148 0L32.92 7.825C34.826 8.886 36 10.845 36 12.968v12.064c0 2.122-1.175 4.083-3.08 5.144l-10.845 6.03a6.345 6.345 0 01-6.148 0l-10.846-6.03C3.175 29.116 2 27.155 2 25.032V12.968c0-2.124 1.175-4.082 3.081-5.143l10.846-6.032z\"/><path fill=\"#CDBFDB\" fill-rule=\"nonzero\" d=\"M5.49 9.148c-1.343.743-2.168 2.109-2.168 3.587v12.53c0 1.477.825 2.845 2.168 3.587l11.336 6.263a4.517 4.517 0 004.35 0l11.336-6.262c1.341-.743 2.166-2.11 2.166-3.588v-12.53c0-1.478-.824-2.844-2.166-3.587L21.177 2.883a4.527 4.527 0 00-4.352 0L5.49 9.148zm10.437-7.355a6.356 6.356 0 016.148 0L32.92 7.825C34.826 8.886 36 10.845 36 12.968v12.064c0 2.122-1.175 4.083-3.08 5.144l-10.845 6.03a6.345 6.345 0 01-6.148 0l-10.846-6.03C3.175 29.116 2 27.155 2 25.032V12.968c0-2.124 1.175-4.082 3.081-5.143l10.846-6.032z\"/><path fill=\"url(#ktzVBqb)\" d=\"M21.667 25.4c.935 0 1.23-.775 1.23-1.6v-1.6c0-1.585.706-3.309 1.641-3.6-.935-.323-1.64-1.215-1.64-2.8v-1.6c0-.825-.296-1.6-1.231-1.6l.41-1.6c1.593 0 2.413 1.393 2.461 2.8v2c-.048 1.585 1.163 2 2.462 2v1.6c-1.299 0-2.51 1.215-2.462 2.8v2c-.048 1.407-.868 2.8-2.461 2.8l-.41-1.6zm-5.334 0l-.41 1.6c-1.593 0-2.413-1.393-2.461-2.8v-2c.048-1.585-1.163-2.8-2.462-2.8v-1.6c1.299 0 2.51-.415 2.462-2v-2c.048-1.407.868-2.8 2.461-2.8l.41 1.6c-.935 0-1.23.775-1.23 1.6v1.6c0 1.585-.706 2.477-1.641 2.8.935.291 1.64 2.015 1.64 3.6v1.6c0 .825.296 1.6 1.231 1.6z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "38",
      "height": "38",
      "viewBox": "0 0 38 38"
    }
  };
});