define("@heroku/ember-hk-components/templates/components/hk-slide-panel/breakout", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Lb3Crtu0",
    "block": "[[[10,0],[14,0,\"hk-slide-panel-breakout-overlay\"],[14,\"data-test-target\",\"slide-panel-breakout-overlay\"],[15,\"onclick\",[28,[37,0],[[30,0],[30,0,[\"onClickOverlay\"]],[28,[37,0],[[30,0],[30,0,[\"dismiss\"]]],null]],null]],[12],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"hk-slide-panel-breakout-content shadow-outer-2 br1\"],[14,\"data-test-target\",\"slide-panel-breakout-content\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,2],null,[[\"dismiss\"],[[28,[37,0],[[30,0],[30,0,[\"dismiss\"]]],null]]]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"action\",\"yield\",\"hash\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-slide-panel/breakout.hbs",
    "isStrictMode": false
  });
});