define("@heroku/ember-hk-components/components/hk-select", ["exports", "emberx-select/components/x-select", "emberx-select/templates/components/x-select"], function (_exports, _xSelect, _xSelect2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _xSelect.default.extend({
    layout: _xSelect2.default,
    attributeBindings: ['readonly', 'data-test-target'],
    classNameBindings: ['isValid:b--light-green', 'isValid:hk-focus-ring--green', 'isInvalid:b--light-red', 'isInvalid:hk-focus-ring--red', 'disabled:hk-input--disabled', 'readonly:hk-input--read-only'],
    classNames: ['hk-select']
  });
});