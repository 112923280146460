define("@heroku/ember-hk-components/templates/components/hk-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "sbNJWdH+",
    "block": "[[[41,[30,0,[\"isPending\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@name\",\"@size\",\"@iconClass\",\"@title\"],[\"loading-16\",16,\"fill-gray v-mid\",\"Loading\"]],null],[1,\"\\n\"]],[]],[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[2,[30,0,[\"text\"]]],[1,\"\\n\"]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"malibu-icon\",\"has-block\",\"yield\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-button.hbs",
    "isStrictMode": false
  });
});