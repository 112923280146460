define("ember-svg-jar/inlined/alert-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.708 5.083L5.083.707 9.46 5.083 5.083 9.458z\" fill=\"none\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "class": "alert-diamond",
      "width": "9",
      "height": "9",
      "viewBox": "0 0 9 9",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});