define("ember-svg-jar/inlined/dyno-basic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dyno-basic</title><g clip-path=\"url(#clip0_69_1641)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.873.985a2.374 2.374 0 012.255 0l4.745 2.586C14.57 3.951 15 4.654 15 5.414v5.172c0 .76-.43 1.463-1.127 1.843l-4.745 2.585a2.37 2.37 0 01-2.255 0L2.128 12.43C1.43 12.05 1 11.346 1 10.586V5.414c0-.76.43-1.462 1.128-1.843L6.873.985z\" fill=\"#fff\" stroke=\"#8060A6\"/><path d=\"M8.574 11c1.291 0 2.106-.678 2.106-1.73 0-.765-.61-1.376-1.4-1.418v-.079a1.296 1.296 0 001.124-1.276C10.404 5.565 9.694 5 8.49 5H5.68v6h2.894zM7.213 6.077h.868c.523 0 .827.258.827.686 0 .424-.325.682-.885.682h-.81V6.077zm0 3.846v-1.55h.919c.634 0 .99.274.99.764 0 .507-.347.786-.982.786h-.927z\" fill=\"#9374B7\"/></g><defs><clipPath id=\"clip0_69_1641\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});