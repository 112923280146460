define("torii/providers/twitter-oauth1", ["exports", "torii/providers/oauth1"], function (_exports, _oauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _oauth.default.extend({
    name: 'twitter'
  });
});