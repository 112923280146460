define("ember-svg-jar/inlined/cedar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.575 23.992h0l-4.824 4.825-.146.146v6.199h1.87v.338H7.397v-.338h1.87v-6.199l-.146-.146-4.825-4.825h0A12.872 12.872 0 01.5 14.827c0-3.463 1.347-6.716 3.796-9.165h0l5.14-5.14 5.14 5.14h0a12.873 12.873 0 013.796 9.165c0 3.463-1.348 6.717-3.797 9.165z\" fill=\"#fff\" stroke=\"#592E8B\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "19",
      "height": "36",
      "viewBox": "0 0 19 36",
      "fill": "none"
    }
  };
});