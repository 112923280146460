define("ember-svg-jar/inlined/region-india", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<linearGradient id=\"hmYQgVc\" gradientUnits=\"userSpaceOnUse\" x1=\"5\" x2=\"10\" y1=\"7\" y2=\"12\"><stop offset=\"0\" stop-color=\"#336ddc\"/><stop offset=\"1\" stop-color=\"#1243a0\"/></linearGradient><linearGradient id=\"hmYQgVd\" gradientUnits=\"userSpaceOnUse\" x1=\"-4.9\" x2=\".428\" y1=\"3.43\" y2=\"18.964\"><stop offset=\"0\" stop-color=\"#fff\" stop-opacity=\".303\"/><stop offset=\"1\" stop-color=\"#fff\" stop-opacity=\".01\"/></linearGradient><clipPath id=\"hmYQgVb\"><path d=\"M0 0h20v14H0z\"/></clipPath><mask id=\"hmYQgVa\" height=\"14\" maskUnits=\"userSpaceOnUse\" width=\"20\" x=\"0\" y=\"0\"><rect fill=\"#fff\" height=\"14\" rx=\"2\" width=\"20\"/></mask><g mask=\"url(#hmYQgVa)\" clip-path=\"url(#hmYQgVb)\"><path d=\"M0 0h20v14H0z\" fill=\"#fdfefe\"/><path d=\"M0 10h20v4H0z\" fill=\"#169b62\"/><path d=\"M0 0h20v4H0z\" fill=\"#ff883e\"/><path clip-rule=\"evenodd\" d=\"M10 9.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM11.5 7a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm.5 0a2 2 0 11-4 0 2 2 0 014 0z\" fill=\"url(#hmYQgVc)\" fill-rule=\"evenodd\"/><rect fill=\"url(#hmYQgVd)\" height=\"14\" rx=\"2\" width=\"20\"/><rect height=\"13.25\" rx=\"1.625\" stroke=\"#000\" stroke-opacity=\".15\" stroke-width=\".75\" width=\"19.25\" x=\".375\" y=\".375\"/></g>",
    "attrs": {
      "fill": "none",
      "height": "14",
      "viewBox": "0 0 20 14",
      "width": "20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});