define("hk-components/components/hk-modal-flexible", ["exports", "ember-modal-dialog/components/modal-dialog", "hk-components/templates/components/hk-modal-flexible", "hk-components/mixins/modal-behavior"], function (_exports, _modalDialog, _hkModalFlexible, _modalBehavior) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _modalDialog.default.extend(_modalBehavior.default, {
    animatable: false,
    // opts us out of animating via liquid-fire
    layout: _hkModalFlexible.default,
    actions: {
      close() {
        this._closeModal();
      }
    }
  });
});