define("torii/session/state-machine", ["exports", "torii/lib/state-machine", "@ember/object"], function (_exports, _stateMachine, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /* eslint-disable no-prototype-builtins */

  var transitionTo = _stateMachine.default.transitionTo;
  function copyProperties(data, target) {
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        (0, _object.set)(target, key, data[key]);
      }
    }
  }
  function transitionToClearing(target, propertiesToClear) {
    return function () {
      for (var i; i < propertiesToClear.length; i++) {
        this[propertiesToClear[i]] = null;
      }
      this.transitionTo(target);
    };
  }
  function _default(session) {
    var sm = new _stateMachine.default({
      initialState: 'unauthenticated',
      states: {
        unauthenticated: {
          errorMessage: null,
          isAuthenticated: false,
          // Actions
          startOpen: transitionToClearing('opening', ['errorMessage']),
          startFetch: transitionToClearing('fetching', ['errorMessage'])
        },
        authenticated: {
          // Properties
          currentUser: null,
          isAuthenticated: true,
          startClose: transitionTo('closing')
        },
        opening: {
          isWorking: true,
          isOpening: true,
          // Actions
          finishOpen(data) {
            copyProperties(data, this.states['authenticated']);
            this.transitionTo('authenticated');
          },
          failOpen(errorMessage) {
            this.states['unauthenticated'].errorMessage = errorMessage;
            this.transitionTo('unauthenticated');
          }
        },
        fetching: {
          isWorking: true,
          isFetching: true,
          // Actions
          finishFetch(data) {
            copyProperties(data, this.states['authenticated']);
            this.transitionTo('authenticated');
          },
          failFetch(errorMessage) {
            this.states['unauthenticated'].errorMessage = errorMessage;
            this.transitionTo('unauthenticated');
          }
        },
        closing: {
          isWorking: true,
          isClosing: true,
          isAuthenticated: true,
          // Actions
          finishClose() {
            this.transitionTo('unauthenticated');
          },
          failClose(errorMessage) {
            this.states['unauthenticated'].errorMessage = errorMessage;
            this.transitionTo('unauthenticated');
          }
        }
      }
    });
    sm.session = session;
    return sm;
  }
});