define("@heroku/ember-hk-components/components/hk-well", ["exports", "@ember/component", "@heroku/ember-hk-components/templates/components/hk-well"], function (_exports, _component, _hkWell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _hkWell.default,
    attributeBindings: ['data-test-target'],
    'data-test-target': 'hk-well',
    classNames: ['hk-well']
  });
});