define("ember-modal-dialog/components/overlay", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "ember-modal-dialog/utils/config-utils", "ember-modal-dialog/templates/components/overlay"], function (_exports, _component, _object, _component2, _configUtils, _overlay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let OverlayComponent = _exports.default = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_overlay.default), _dec(_class = _dec2(_class = (_class2 = class OverlayComponent extends _component.default {
    _onClickOverlay(event) {
      let {
        onClickOverlay
      } = this;
      if (onClickOverlay) {
        onClickOverlay(event);
      }
    }
    didInsert(element) {
      const registerClick = () => {
        element.addEventListener('click', this._onClickOverlay);
      };

      // setTimeout needed or else the click handler will catch the click that spawned this modal dialog
      setTimeout(registerClick, (0, _configUtils.clickHandlerDelay)(this));
      if (_configUtils.isIOS) {
        element.style.cursor = 'pointer';
      }
    }
    willDestroyNode(element) {
      element.removeEventListener('click', this._onClickOverlay);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "_onClickOverlay", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onClickOverlay"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "didInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "willDestroyNode", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "willDestroyNode"), _class2.prototype)), _class2)) || _class) || _class);
});