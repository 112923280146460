define("ember-mockdate-shim/index", ["exports", "ember", "@ember/object"], function (_exports, _ember, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unfreezeDate = _exports.freezeDateAt = void 0;
  /* globals MockDate */

  const originalDate = Date;
  const originalPlatformNow = (0, _object.get)(_ember.default, 'run.backburner._platform.now');
  const {
    set,
    reset
  } = MockDate || {
    set() {},
    reset() {}
  };

  /*
   * The backburner.js _platform.now function must be overridden when using this
   * addon in acceptance tests that return async model hooks. Otherwise the ember
   * run loop gets itself into an infinite loop as time will never progress.
   *
   * See this backburner PR for more info:
   * https://github.com/BackburnerJS/backburner.js/pull/264
   */
  const freezeDateAt = (...args) => {
    (0, _object.trySet)(_ember.default, 'run.backburner._platform.now', () => originalDate());
    set(args);
  };
  _exports.freezeDateAt = freezeDateAt;
  const unfreezeDate = (...args) => {
    (0, _object.trySet)(_ember.default, 'run.backburner._platform.now', originalPlatformNow);
    reset(args);
  };
  _exports.unfreezeDate = unfreezeDate;
});