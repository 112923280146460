define("@adopted-ember-addons/ember-stripe-elements/components/stripe-elements", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    card=(component "stripe-card"
      _elements=this.elements
    )
    cardNumber=(component "stripe-card-number"
      _elements=this.elements
    )
    cardExpiry=(component "stripe-card-expiry"
      _elements=this.elements
    )
    cardCvc=(component "stripe-card-cvc"
      _elements=this.elements
    )
    postalCode=(component "stripe-postal-code"
      _elements=this.elements
    )
  )}}
  
  */
  {
    "id": "VT/LlkAo",
    "block": "[[[18,1,[[28,[37,1],null,[[\"card\",\"cardNumber\",\"cardExpiry\",\"cardCvc\",\"postalCode\"],[[50,\"stripe-card\",0,null,[[\"_elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-card-number\",0,null,[[\"_elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-card-expiry\",0,null,[[\"_elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-card-cvc\",0,null,[[\"_elements\"],[[30,0,[\"elements\"]]]]],[50,\"stripe-postal-code\",0,null,[[\"_elements\"],[[30,0,[\"elements\"]]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@adopted-ember-addons/ember-stripe-elements/components/stripe-elements.hbs",
    "isStrictMode": false
  });
  let StripeElements = _exports.default = (_dec = (0, _service.inject)('stripev3'), (_class = class StripeElements extends _component2.default {
    get options() {
      return this.args.options || {};
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "stripe", _descriptor, this);
      this.elements = this.stripe.elements(this.options);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stripe", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StripeElements);
});