define("@adopted-ember-addons/ember-stripe-elements/components/stripe-element", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let StripeElement = _exports.default = (_class = class StripeElement extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "stripeElement", _descriptor, this);
      _initializerDefineProperty(this, "type", _descriptor2, this);
      // Set in components that extend from `stripe-element`
      _initializerDefineProperty(this, "_stripeError", _descriptor3, this);
      _initializerDefineProperty(this, "stripev3", _descriptor4, this);
    }
    get autofocus() {
      return this.args.autofocus;
    }
    get options() {
      return this.args.options || {};
    }
    get elements() {
      if (this.args._elements) {
        return this.args._elements;
      }
      return this.stripev3.elements();
    }
    get stripeError() {
      return this.args.stripeError || this._stripeError;
    }
    set stripeError(error) {
      this._stripeError = error;
    }
    registerListeners(element) {
      this.mountElement(element);
      this.setEventListeners();
      this.focusElement(element);
    }
    mountElement(element) {
      // Fetch user options
      let options = this.args.options;

      // `stripeElement` instead of `element` to distinguish from `element`
      let stripeElement = this.elements.create(this.type, options);

      // Mount the Stripe Element onto the mount point
      stripeElement.mount(element);

      // Make the element available to the component
      this.stripeElement = stripeElement;
      this.stripev3.addStripeElement(stripeElement);
    }
    focusElement(element) {
      // Fetch autofocus, set by user
      let iframe = element.querySelector('iframe');
      if (this.autofocus && iframe) {
        iframe.onload = () => {
          this.stripeElement.focus();
        };
      }
    }
    setEventListeners() {
      let {
        stripeElement
      } = this;
      stripeElement.on('ready', event => {
        this._invokeAction('onReady', stripeElement, event);
      });
      stripeElement.on('blur', event => {
        this._invokeAction('onBlur', stripeElement, event);
      });
      stripeElement.on('focus', event => {
        this._invokeAction('onFocus', stripeElement, event);
      });
      stripeElement.on('change', (...args) => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        let [{
          complete,
          error: stripeError
        }] = args;
        this.args.onChange?.(stripeElement, ...args);
        if (complete) {
          this._invokeAction('onComplete', stripeElement);
        } else if (stripeError) {
          this._invokeAction('onError', stripeError);
        }
        this.stripeError = stripeError;
      });
    }
    _invokeAction(method, ...args) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      if (typeof this.args[method] === 'function') {
        this.args[method](...args);
      }
    }
    onOptionsChange() {
      let options = this.options;
      this.stripeElement.update(options);
    }
    willDestroy() {
      this.stripeElement.unmount();
      this.stripev3.removeStripeElement(this.stripeElement);
      super.willDestroy(...arguments);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stripeElement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_stripeError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stripev3", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "registerListeners", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "registerListeners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOptionsChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onOptionsChange"), _class.prototype)), _class);
});