define("torii/lib/container-utils", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getOwner = getOwner;
  _exports.hasRegistration = hasRegistration;
  _exports.lookup = lookup;
  _exports.lookupFactory = lookupFactory;
  _exports.register = register;
  function hasRegistration(application, name) {
    if (application && application.hasRegistration) {
      return application.hasRegistration(name);
    } else {
      return application.registry.has(name);
    }
  }
  function register(applicationInstance, name, factory) {
    if (applicationInstance && applicationInstance.application) {
      return applicationInstance.application.register(name, factory);
    } else {
      return applicationInstance.registry.register(name, factory);
    }
  }
  function lookupFactory(applicationInstance, name) {
    if (applicationInstance && applicationInstance.lookupFactory) {
      return applicationInstance.lookupFactory(name);
    } else if (applicationInstance && applicationInstance.resolveRegistration) {
      // See https://github.com/Vestorly/torii/issues/362
      return applicationInstance.resolveRegistration(name);
    } else if (applicationInstance && applicationInstance.application) {
      return applicationInstance.application.__container__.lookupFactory(name);
    } else {
      return applicationInstance.container.lookupFactory(name);
    }
  }
  function lookup(applicationInstance, name) {
    if (applicationInstance && applicationInstance.lookup) {
      return applicationInstance.lookup(name);
    } else if (applicationInstance && applicationInstance.application) {
      return applicationInstance.application.__container__.lookup(name);
    } else {
      return applicationInstance.container.lookup(name);
    }
  }
  function getOwner(instance) {
    if (_application.getOwner) {
      return (0, _application.getOwner)(instance);
    } else {
      return instance.container;
    }
  }
});