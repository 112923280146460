define("ember-svg-jar/inlined/dyno-p2xl-ram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dyno-p2xl-ram</title><g clip-path=\"url(#clip0_69_1712)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.873.985a2.374 2.374 0 012.255 0l4.745 2.586C14.57 3.951 15 4.654 15 5.414v5.172c0 .76-.43 1.463-1.127 1.843l-4.745 2.585a2.37 2.37 0 01-2.255 0L2.128 12.43C1.43 12.05 1 11.346 1 10.586V5.414c0-.76.43-1.462 1.128-1.843L6.873.985z\" fill=\"url(#paint0_linear_69_1712)\"/><path d=\"M2.503 10.18v-.852L4.13 7.982c.108-.09.2-.173.277-.252a.953.953 0 00.18-.245.607.607 0 00.063-.279.544.544 0 00-.072-.287.47.47 0 00-.196-.184.614.614 0 00-.29-.066.614.614 0 00-.29.066.46.46 0 00-.191.194.654.654 0 00-.069.311H2.417c0-.306.07-.57.207-.792a1.35 1.35 0 01.584-.512c.251-.119.546-.179.884-.179.35 0 .652.057.908.169.257.11.455.267.594.468a1.287 1.287 0 01.107 1.21 2.05 2.05 0 01-.375.548c-.18.2-.437.44-.77.716l-.408.341v.026h1.704v.946h-3.35zm4.96-4.363l.716 1.27h.034l.724-1.27h1.321L8.954 8l1.355 2.182H8.954l-.741-1.296h-.034l-.742 1.296H6.091l1.338-2.182-1.296-2.182h1.33zm3.126 4.364V5.817h1.185v3.41h1.764v.954H10.59z\" fill=\"#fff\"/></g><defs><linearGradient id=\"paint0_linear_69_1712\" x1=\"-6\" y1=\"8\" x2=\"8.587\" y2=\"21.988\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#AB8ECD\"/><stop offset=\"1\" stop-color=\"#79589F\"/></linearGradient><clipPath id=\"clip0_69_1712\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});