define("@heroku/ember-hk-components/components/hk-textarea", ["exports", "@ember/legacy-built-in-components"], function (_exports, _legacyBuiltInComponents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _legacyBuiltInComponents.TextArea.extend({
    attributeBindings: ['data-test-target'],
    classNameBindings: ['isValid:b--light-green', 'isValid:hk-focus-ring--green', 'isInvalid:b--light-red', 'isInvalid:hk-focus-ring--red', 'disabled:hk-input--disabled', 'readonly:hk-input--read-only'],
    classNames: ['hk-input']
  });
});