define("@heroku/ember-hk-components/templates/components/hk-input-with-validation-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "PK7gidlq",
    "block": "[[[10,0],[14,0,\"flex\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"isValid\",\"isInvalid\",\"key-up\",\"id\",\"value\",\"name\",\"class\",\"autofocus\",\"disabled\",\"maxlength\",\"placeholder\",\"readonly\",\"aria-describedby\"],[[30,0,[\"isValid\"]],[30,0,[\"isInvalid\"]],[28,[37,1],[[30,0],\"onChange\"],null],[30,0,[\"id\"]],[30,0,[\"inputValue\"]],[30,0,[\"name\"]],[30,0,[\"inputClasses\"]],[30,0,[\"autofocus\"]],[30,0,[\"disabled\"]],[30,0,[\"maxlength\"]],[30,0,[\"placeholder\"]],[30,0,[\"readonly\"]],[30,0,[\"errorsListId\"]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"isShowingStatus\"]],[[[41,[30,0,[\"isValidating\"]],[[[1,\"      \"],[10,0],[14,0,\"flex items-center bg-light-silver br1 br--right bt br bb b--light-gray ph2\"],[14,\"data-test-target\",\"loading-icon\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"loading-16\",\"malibu-fill-gradient-dark-gray\",16,\"Loading\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isValid\"]],[[[1,\"      \"],[10,0],[14,0,\"flex items-center bg-lightest-green br1 br--right bt br bb b--light-green ph2\"],[14,\"data-test-target\",\"valid-icon\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"success-badge-16\",\"malibu-fill-gradient-green\",16,[29,[[36,4],\" is valid\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"flex items-center bg-lightest-red br1 br--right bt br bb b--light-red ph2\"],[14,\"data-test-target\",\"invalid-icon\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"failure-badge-16\",\"malibu-fill-gradient-red\",16,[29,[[36,4],\" is invalid\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]],[]]]],[]],null],[13]],[],false,[\"hk-input\",\"action\",\"if\",\"malibu-icon\",\"name\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-input-with-validation-status.hbs",
    "isStrictMode": false
  });
});