define("@heroku/ember-hk-components/templates/components/hk-validation-errors-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Q9cGBJli",
    "block": "[[[10,\"ul\"],[15,0,[29,[\"list pl0 red mv2 f5 normal lh-copy \",[52,[51,[30,0,[\"hasValidationErrors\"]]],\"clip\"]]]],[15,1,[30,0,[\"errorsListId\"]]],[15,\"data-test-target\",[29,[\"validation-errors-\",[30,0,[\"property\"]]]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"validationErrorMessages\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]],null],[13]],[\"message\"],false,[\"unless\",\"each\",\"-track-array\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-validation-errors-list.hbs",
    "isStrictMode": false
  });
});