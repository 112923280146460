define("@heroku/ember-hk-components/components/hk-slide-panel", ["exports", "@ember/component", "@ember/runloop", "@ember/object", "@ember/object/computed", "@ember/application", "@heroku/ember-hk-components/templates/components/hk-slide-panel"], function (_exports, _component, _runloop, _object, _computed, _application, _hkSlidePanel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ESC_KEY = 27;

  /**
   `hk-slide-panel` gives you an interface for sliding a panel into view
   with a specific header and content which you sepcify.
  
   ### Usage
  
   #### Default
  
   ```handlebars
    {{#hk-slide-panel close=(action "closeAction") as |sp|}}
      {{sp.header content="Panel Title"}}
      {{#sp.content}}
        Content to display inside the panel
      {{/sp.content}}
    {{/hk-slide-panel}}
   ```
  
   #### Customized
  
   You can also customize the size, direction, header
   content of the panel as well as if clicking the overlay closes it
   ```handlebars
    {{#hk-slide-panel
      slideFrom="bottom"
      panelSize="large"
      clickOverlayToClose=clickOverlayToClose
      containerClassNames="class-name-for-panel-container"
      close=(action "closeAction")
      as |sp|}}
      {{#sp.header}}
        <div>Custom Header Stuff</div>
      {{/sp.header}}
      {{#sp.content}}
        Content to display inside the panel
      {{/sp.content}}
    {{/hk-slide-panel}}
   ```
  */
  var _default = _exports.default = _component.default.extend({
    layout: _hkSlidePanel.default,
    attributeBindings: ['data-test-target'],
    isOpen: false,
    clickOverlayToClose: true,
    trapFocus: true,
    isTest: (0, _object.computed)(function () {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return config.environment === 'test';
    }),
    destinationId: (0, _object.computed)('isTest', function () {
      if (this.get('isTest')) {
        return 'ember-testing';
      } else {
        return 'hk-slide-panels';
      }
    }),
    slideDirection: (0, _object.computed)('slideFrom', function () {
      if (this.get('slideFrom')) {
        return `from-${this.get('slideFrom')}`;
      }
      return 'from-right';
    }),
    panelDimensions: (0, _object.computed)('panelSize', function () {
      if (this.get('panelSize')) {
        return this.get('panelSize');
      }
      return 'standard';
    }),
    focusTrapIsActive: (0, _computed.and)('trapFocus', 'isOpen'),
    didInsertElement() {
      this._super(...arguments);
      this._initEscListener();
      document.body.classList.add('panel-is-visible');
      (0, _runloop.scheduleOnce)('afterRender', this, this._setupPanel);
    },
    willDestroyElement() {
      document.body.classList.remove('panel-is-visible');
      this._super(...arguments);
    },
    _setupPanel() {
      // Allows us to animate via this class _after_ the component exists
      (0, _runloop.later)(() => {
        if (!this.get('isDestroyed')) {
          this.set('isOpen', true);

          // optional action to be passed in that will be fired once
          // this component is done rendering
          if (this.get('onElementReady')) {
            (0, _runloop.later)(() => {
              this.get('onElementReady')();
            }, 250);
          }
        }
      });
    },
    _initEscListener() {
      const closeOnEscapeKey = ev => {
        if (ev.keyCode === ESC_KEY) {
          this.send('dismiss');
        }
      };
      window.addEventListener('keydown', closeOnEscapeKey);
      this.on('willDestroyElement', () => {
        window.removeEventListener('keydown', closeOnEscapeKey);
      });
    },
    actions: {
      dismiss() {
        // this allows us to animate the disappearance of the slider _before
        // it is removed from the DOM
        this.set('isOpen', false);
        if (this.get('isTest')) {
          this.get('close')();
        } else {
          (0, _runloop.later)(() => {
            this.get('close')();
          }, 400);
        }
      }
    }
  });
});