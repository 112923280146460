define("ember-svg-jar/inlined/dyno-space-pxl-ram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dyno-space-pxl-ram</title><g clip-path=\"url(#clip0_227_190)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.499 2.917c.277 0 .501.23.501.509V6.49a.507.507 0 01-.501.509H10.5A.507.507 0 0110 6.491V3.426c0-.281.228-.51.501-.51h.399v-.585C10.9 1.044 11.843 0 13 0c1.16 0 2.1 1.048 2.1 2.33v.587h.399zM9.708 1.846L8.128.985a2.374 2.374 0 00-2.255 0L1.128 3.57C.43 3.951 0 4.654 0 5.414v5.172c0 .76.43 1.463 1.128 1.843l4.745 2.585a2.37 2.37 0 002.255 0l4.745-2.585C13.57 12.05 14 11.346 14 10.586V8H9.668A.664.664 0 019 7.346V2.5c0-.361.304-.654.668-.654h.04zM14 2.32V3h-2v-.68c0-.73.444-1.32 1-1.32.552 0 1 .592 1 1.32z\" fill=\"url(#paint0_linear_227_190)\"/><mask style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"16\" height=\"16\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.128.985a2.374 2.374 0 00-2.255 0L1.128 3.57C.43 3.951 0 4.654 0 5.414v5.172c0 .76.43 1.463 1.128 1.843l4.745 2.585a2.37 2.37 0 002.255 0l4.745-2.585C13.57 12.05 14 11.346 14 10.586V5.414c0-.76-.43-1.462-1.127-1.843L8.128.985zm-.193 1.25a1.981 1.981 0 00-1.869 0L2.134 4.36c-.578.313-.934.89-.934 1.514v4.252c0 .624.356 1.202.934 1.514l3.932 2.125c.578.313 1.29.313 1.869 0l3.931-2.125c.578-.312.934-.89.934-1.514V5.875c0-.626-.356-1.202-.934-1.515L7.935 2.234z\" fill=\"#fff\"/></mask><path d=\"M4.373 5.78l.716 1.27h.034l.725-1.27h1.32L5.866 7.962l1.355 2.181H5.865l-.742-1.295H5.09l-.741 1.295H3l1.338-2.181L3.044 5.78h1.33zM7.5 10.143V5.78h1.185v3.409h1.764v.954H7.5z\" fill=\"#fff\"/></g><defs><linearGradient id=\"paint0_linear_227_190\" x1=\"-8\" y1=\"7.65\" x2=\"7.285\" y2=\"23.634\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8EBDF1\"/><stop offset=\"1\" stop-color=\"#006DEB\"/></linearGradient><clipPath id=\"clip0_227_190\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});