define("@heroku/ember-hk-components/templates/components/hk-label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/muGAvAD",
    "block": "[[[18,1,null],[1,\"\\n\"],[41,[30,0,[\"isRequired\"]],[[[1,\"  \"],[10,1],[14,0,\"br1 ph1 ml1 bg-gray f6 white\"],[14,\"data-test-target\",\"required-label\"],[12],[1,\"Required\"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"yield\",\"if\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-label.hbs",
    "isStrictMode": false
  });
});