define("ember-svg-jar/inlined/ci", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.714 8.357a7.071 7.071 0 01-11.34 5.638 13.32 13.32 0 00-.402 6.391L3.124 22.11A15.444 15.444 0 012.571 18c0-2.054.402-4.014 1.13-5.807a7.071 7.071 0 0110.583-9.171c1.19-.294 2.435-.45 3.716-.45a15.38 15.38 0 0110.897 4.506l2.13-2.005 1.116 7.566-8.005-1.08 3.167-2.982A13.2 13.2 0 0018 4.757c-.738 0-1.463.06-2.168.177a7.04 7.04 0 01.882 3.423zM4.974 30.89l2.11-1.987A15.38 15.38 0 0018 33.43c1.281 0 2.526-.157 3.716-.45a7.071 7.071 0 0010.583-9.171 15.385 15.385 0 001.13-5.808c0-1.423-.193-2.8-.553-4.108l-1.847 1.722a13.323 13.323 0 01-.403 6.391 7.071 7.071 0 00-10.458 9.061c-.705.116-1.43.177-2.168.177a13.2 13.2 0 01-9.324-3.839l3.186-3-8.005-1.08 1.116 7.566zM6.741 8.38l1.32 1.319 3.615-3.615a.799.799 0 011.131 1.131L8.68 11.343a.792.792 0 01-.638.225.81.81 0 01-.609-.235L5.61 9.511a.802.802 0 01-.006-1.138.813.813 0 011.138.006zm17.853 16.356l1.796 1.796 1.795-1.796a.81.81 0 111.145 1.145l-1.796 1.796 1.796 1.795a.81.81 0 11-1.145 1.144l-1.795-1.795-1.796 1.796a.81.81 0 11-1.144-1.145l1.795-1.796-1.795-1.795a.81.81 0 111.144-1.144z\" fill=\"#79589F\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});