define("ember-svg-jar/inlined/dyno-2x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dyno-2x</title><g clip-path=\"url(#clip0_69_1683)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.873.985a2.374 2.374 0 012.255 0l4.745 2.586C14.57 3.951 15 4.654 15 5.414v5.172c0 .76-.43 1.463-1.127 1.843l-4.745 2.585a2.37 2.37 0 01-2.255 0L2.128 12.43C1.43 12.05 1 11.346 1 10.586V5.414c0-.76.43-1.462 1.128-1.843L6.873.985z\" fill=\"#F0EEF4\" stroke=\"#8060A6\"/><path d=\"M3.66 11.072V9.793L6.1 7.773c.162-.134.3-.26.416-.377a1.43 1.43 0 00.268-.367.91.91 0 00.096-.42.816.816 0 00-.109-.43.704.704 0 00-.294-.275.92.92 0 00-.434-.1.92.92 0 00-.435.1.689.689 0 00-.288.29.98.98 0 00-.102.467H3.532c0-.46.103-.857.31-1.189.206-.332.498-.588.875-.767.377-.179.82-.268 1.327-.268.524 0 .978.084 1.361.252.386.166.683.4.892.703.21.303.316.657.316 1.064 0 .252-.052.502-.156.752-.105.247-.292.52-.563.82-.27.301-.655.66-1.154 1.075l-.613.511v.038h2.557v1.42H3.659z\" fill=\"url(#paint0_linear_69_1683)\"/><path d=\"M9.71 9.713l-.634-.634 3.097-3.151.633.646L9.71 9.713zm2.469.024l-3.073-3.17.634-.657 3.079 3.169-.64.658z\" fill=\"url(#paint1_linear_69_1683)\"/></g><defs><linearGradient id=\"paint0_linear_69_1683\" x1=\"-.493\" y1=\"8.072\" x2=\"10.252\" y2=\"14.729\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#AB8ECD\"/><stop offset=\"1\" stop-color=\"#79589F\"/></linearGradient><linearGradient id=\"paint1_linear_69_1683\" x1=\"7.204\" y1=\"7.823\" x2=\"11.031\" y2=\"11.565\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#AB8ECD\"/><stop offset=\"1\" stop-color=\"#79589F\"/></linearGradient><clipPath id=\"clip0_69_1683\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});