define("@heroku/ember-hk-components/components/hk-slide-panel/overlay", ["exports", "@ember/component", "@heroku/ember-hk-components/templates/components/hk-slide-panel/overlay"], function (_exports, _component, _overlay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _overlay.default,
    attributeBindings: ['data-test-target'],
    'data-test-target': 'slide-panel',
    classNames: ['hk-slide-panel-overlay'],
    classNameBindings: ['isOpen:is-visible'],
    mouseDown(event) {
      if (event.target === this.get('element') && this.get('clickOverlayToClose') !== false) {
        this.get('onClick')();
      }
    }
  });
});