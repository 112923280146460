define("@heroku/ember-hk-components/templates/components/hk-inline-edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "x1rb0Q7Y",
    "block": "[[[10,\"form\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"hk-label db mb2\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,[30,0,[\"labelWithAssert\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex flex-column flex-row-ns items-center-ns w-100\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"type\",\"value\",\"disabled\",\"maxlength\",\"placeholder\",\"id\",\"autocomplete\",\"data-test-target\",\"class\",\"key-up\"],[\"text\",[30,0,[\"deferredValue\"]],[30,0,[\"isDisabled\"]],[30,0,[\"maxlength\"]],[30,0,[\"placeholder\"]],[30,0,[\"inputId\"]],\"off\",\"hk-inline-value\",\"mr2 mb2 w-100 mw6\",[28,[37,1],[[30,0],\"valueChanged\",[30,0,[\"valueHasChanged\"]]],null]]]]],[1,\"\\n    \"],[10,0],[15,0,[29,[\"flex items-center mb2 \",[52,[51,[30,0,[\"valueHasChanged\"]]],\"hidden\"]]]],[14,\"data-test-target\",\"hk-inline-buttons\"],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"onClick\",\"type\",\"default\",\"disableWhen\",\"buttonType\",\"data-test-target\",\"class\"],[[28,[37,1],[[30,0],\"save\",[30,0,[\"deferredValue\"]]],null],\"submit\",\"Save\",[30,0,[\"isUpdateDisabled\"]],\"primary\",\"hk-inline-submit\",\"mr1\"]]]],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"onClick\",\"type\",\"default\",\"buttonType\",\"data-test-target\"],[[28,[37,1],[[30,0],\"cancelEdit\"],null],\"cancel\",\"Cancel\",\"secondary\",\"hk-inline-cancel\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"hk-input\",\"action\",\"unless\",\"hk-button\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-inline-edit.hbs",
    "isStrictMode": false
  });
});