define("@heroku/ember-hk-components/templates/components/hk-slide-panel/overlay", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "0I09I1Nk",
    "block": "[[[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-slide-panel/overlay.hbs",
    "isStrictMode": false
  });
});