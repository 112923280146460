define("ember-svg-jar/inlined/mfa-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\"><path d=\"M13 25h38v33H13z\" fill=\"#00a1e0\" stroke=\"#032e61\"/><g fill=\"none\"><path d=\"M43 25v-8A11 11 0 0032 6a11 11 0 00-11 11v8\" stroke=\"#032e61\"/><path d=\"M24 42l5 5 11-11\" stroke=\"#fff\"/></g></g>",
    "attrs": {
      "viewBox": "0 0 64 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});