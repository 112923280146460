define("hk-components/templates/components/hk-danger-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="hk-danger-message-heading flex items-center mt1">
    <MalibuIcon @name="failure-ring-16" @iconClass="malibu-fill-gradient-red mh1 nudge-up--1" @size={{16}} @title="Warning" />
    <span class="b">{{this.heading}}</span>
  </div>
  
  <div class="hk-danger-message-body ml4 mr2 mv1 pl1">
    {{yield}}
  </div>
  */
  {
    "id": "gQ4CkZtf",
    "block": "[[[10,0],[14,0,\"hk-danger-message-heading flex items-center mt1\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@name\",\"@iconClass\",\"@size\",\"@title\"],[\"failure-ring-16\",\"malibu-fill-gradient-red mh1 nudge-up--1\",16,\"Warning\"]],null],[1,\"\\n  \"],[10,1],[14,0,\"b\"],[12],[1,[30,0,[\"heading\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"hk-danger-message-body ml4 mr2 mv1 pl1\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"malibu-icon\",\"yield\"]]",
    "moduleName": "hk-components/templates/components/hk-danger-message.hbs",
    "isStrictMode": false
  });
});