define("hk-components/components/hk-danger-message", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['ba', 'br2', 'b--light-red', 'bg-lightest-red', 'red', 'pa1']
  });
});