define("ember-svg-jar/inlined/control-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M42.896 32.002a5.186 5.186 0 01-2.587 4.481l-13.861 8.003a5.194 5.194 0 01-5.175 0l-13.86-8.002A5.19 5.19 0 014.824 32V15.998c0-1.842.992-3.56 2.589-4.482l13.86-8.002a5.187 5.187 0 015.175 0l13.861 8.002a5.188 5.188 0 012.587 4.482V32zM41.721 9.067L27.86 1.066a8.033 8.033 0 00-7.999 0L6 9.07a8.025 8.025 0 00-4 6.928v16.005a8.025 8.025 0 004 6.928l13.861 8.002A8 8 0 0023.86 48a8 8 0 004-1.067l13.861-8.002a8.025 8.025 0 003.999-6.928V15.997a8.025 8.025 0 00-3.999-6.928zM20.035 25.675a1.455 1.455 0 01-1.453-1.454 1.454 1.454 0 111.453 1.454M35.14 22.81H24.054c-.587-1.662-2.16-2.865-4.02-2.865-1.86 0-3.431 1.203-4.018 2.865h-2.9a1.412 1.412 0 100 2.824h2.9c.587 1.663 2.159 2.865 4.019 2.865 1.86 0 3.433-1.202 4.019-2.865H35.14a1.411 1.411 0 100-2.824m-9.115 11.285a1.455 1.455 0 010-2.908 1.455 1.455 0 010 2.908m9.115-2.865h-5.095c-.587-1.664-2.16-2.866-4.02-2.866-1.86 0-3.432 1.202-4.02 2.866h-8.89a1.412 1.412 0 100 2.824h8.89c.588 1.663 2.16 2.865 4.02 2.865 1.86 0 3.433-1.202 4.02-2.865h5.095a1.411 1.411 0 100-2.824m-2.867-13.974a1.455 1.455 0 01-1.453-1.453 1.455 1.455 0 012.908 0c0 .801-.652 1.453-1.455 1.453m0-5.73c-1.86 0-3.432 1.202-4.02 2.865H13.118a1.411 1.411 0 100 2.824h15.138c.587 1.662 2.159 2.865 4.019 2.865a4.284 4.284 0 004.279-4.277 4.283 4.283 0 00-4.28-4.277\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48"
    }
  };
});