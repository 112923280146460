define("ember-svg-jar/inlined/dyno-tier-hobby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><linearGradient id=\"cewNwqa\" x1=\"0%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#FFF\"/><stop offset=\"100%\" stop-color=\"#EDE6F5\"/></linearGradient><linearGradient id=\"cewNwqb\" x1=\"0%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#AB8ECD\"/><stop offset=\"100%\" stop-color=\"#79589F\"/></linearGradient></defs><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"url(#cewNwqa)\" d=\"M25.12 3.233a10.281 10.281 0 019.763 0l17.227 9.384c3.025 1.65 4.89 4.698 4.89 8v18.767c0 3.3-1.866 6.35-4.89 8l-17.226 9.38a10.264 10.264 0 01-9.764 0l-17.226-9.38c-3.027-1.65-4.894-4.7-4.894-8V20.617c0-3.303 1.866-6.35 4.894-8L25.12 3.232z\"/><path fill=\"url(#cewNwqb)\" d=\"M8.85 14.372C6.457 15.677 5 18.057 5 20.617v18.767c0 2.558 1.457 4.94 3.85 6.244l17.227 9.38a8.264 8.264 0 007.85 0l17.224-9.38C53.543 44.325 55 41.942 55 39.385V20.617c0-2.559-1.455-4.94-3.846-6.244L33.928 4.99a8.281 8.281 0 00-7.852 0L8.85 14.372zM25.12 3.233a10.281 10.281 0 019.763 0l17.227 9.384c3.025 1.65 4.89 4.698 4.89 8v18.767c0 3.3-1.866 6.35-4.89 8l-17.226 9.38a10.264 10.264 0 01-9.764 0l-17.226-9.38c-3.027-1.65-4.894-4.7-4.894-8V20.617c0-3.303 1.866-6.35 4.894-8L25.12 3.232z\"/><path fill=\"url(#cewNwqb)\" d=\"M39.67 21.31a3 3 0 012.874 5.262c.297 1.093.456 2.242.456 3.428 0 7.18-5.82 13-13 13s-13-5.82-13-13 5.82-13 13-13c3.84 0 7.29 1.664 9.67 4.31zm-.814.592A11.969 11.969 0 0030 18c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12c0-1.058-.137-2.084-.394-3.061a3 3 0 01-2.75-5.037zM30 36a6 6 0 110-12 6 6 0 010 12zm0-2a4 4 0 100-8 4 4 0 000 8z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "60",
      "height": "60",
      "viewBox": "0 0 60 60"
    }
  };
});