define("ember-svg-jar/inlined/cc-mastercard-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><linearGradient id=\"gccbWsa\" x1=\"50%\" x2=\"50%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#FFF\"/><stop offset=\"100%\" stop-color=\"#FFF7ED\"/></linearGradient></defs><g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"22\" height=\"16\" fill=\"url(#gccbWsa)\" rx=\"3\"/><path fill=\"#000\" fill-opacity=\".101\" fill-rule=\"nonzero\" d=\"M2.994.75A2.247 2.247 0 00.75 3.003v9.994a2.25 2.25 0 002.244 2.253h16.012a2.247 2.247 0 002.244-2.253V3.003A2.25 2.25 0 0019.006.75H2.994zm0-.75h16.012A3 3 0 0122 3.003v9.994A2.997 2.997 0 0119.006 16H2.994A3 3 0 010 12.997V3.003A2.997 2.997 0 012.994 0z\"/><g fill-rule=\"nonzero\" transform=\"translate(4 4)\"><circle cx=\"4\" cy=\"4\" r=\"4\" fill=\"#EB001B\"/><circle cx=\"10\" cy=\"4\" r=\"4\" fill=\"#F79E1B\"/><path fill=\"#FF5F00\" d=\"M7 1.354C7.622 2.06 8 2.986 8 4s-.378 1.94-1 2.646A3.985 3.985 0 016 4c0-1.014.378-1.94 1-2.646z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "22",
      "height": "16",
      "viewBox": "0 0 22 16"
    }
  };
});