define("torii/bootstrap/session", ["exports", "torii/services/torii-session"], function (_exports, _toriiSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(application, sessionName) {
    var sessionFactoryName = 'service:' + sessionName;
    application.register(sessionFactoryName, _toriiSession.default);
  }
});