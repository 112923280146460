define("ember-changeset/helpers/changeset-get", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changesetGet = changesetGet;
  _exports.default = void 0;
  function changesetGet([changeset, fieldPath]) {
    return changeset.get(fieldPath);
  }
  var _default = _exports.default = (0, _helper.helper)(changesetGet);
});