define("@heroku/ember-hk-components/components/hk-validation-errors-list", ["exports", "@ember/component", "@ember/object/computed", "@heroku/ember-hk-components/templates/components/hk-validation-errors-list"], function (_exports, _component, _computed, _hkValidationErrorsList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _hkValidationErrorsList.default,
    tagName: '',
    hasValidationErrors: (0, _computed.bool)('validationErrors'),
    validationErrorMessages: (0, _computed.alias)('validationErrors.validation')
  });
});