define("ember-svg-jar/inlined/region-generic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><linearGradient id=\"bBCJkqb\" x1=\"1.535%\" y1=\"1.535%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#F5F7FC\"/><stop offset=\"100%\" stop-color=\"#CFD7E6\"/></linearGradient><linearGradient id=\"bBCJkqc\" x1=\"100%\" x2=\"0%\" y1=\"100%\" y2=\"0%\"><stop offset=\"0%\" stop-color=\"#596981\"/><stop offset=\"100%\" stop-color=\"#828EA0\"/></linearGradient><rect id=\"bBCJkqa\" width=\"20\" height=\"14\" rx=\"2\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"bBCJkqd\" fill=\"#fff\"><use xlink:href=\"#bBCJkqa\"/></mask><use fill=\"url(#bBCJkqb)\" xlink:href=\"#bBCJkqa\"/><path fill=\"url(#bBCJkqc)\" fill-rule=\"nonzero\" d=\"M10 2C7.25 2 5 4.25 5 7s2.25 5 5 5 5-2.25 5-5-2.25-5-5-5zm.75 9.083c-.083-.291-.292-.458-.583-.875-.417-.541-.209-1.333-1.542-1.958-.958-.458-1.25-.958-1.417-1.917-.125-.625-.208-1.041 0-1.458.292-.583.292-1 .292-1.25.708-.5 1.542-.833 2.458-.833.584 0 1.167.125 1.667.333-.125.25-.417.417-.667.333-.333-.125-1.458-.208-1.5.5-.041.709.5.875 1.542.125.208-.166.458-.125.708 0 .25.125.459.209.459.542-.042.542-1.334 1-1.584 1.875-.166.542.459.917-.25 1-.416.042-.75-1.083-1.416-.542-.709.625.416.709.583.834.708.416.25 1.083 1.167.75.541-.209.916-.292 1.5-.125.291.083.916.583 1.25.791-.542 1-1.5 1.667-2.667 1.875z\" mask=\"url(#bBCJkqd)\"/><path fill=\"#000\" fill-opacity=\".151\" fill-rule=\"nonzero\" d=\"M2.994.75A2.247 2.247 0 00.75 3.003v7.994a2.25 2.25 0 002.244 2.253h14.012a2.247 2.247 0 002.244-2.253V3.003A2.25 2.25 0 0017.006.75H2.994zm0-.75h14.012A3 3 0 0120 3.003v7.994A2.997 2.997 0 0117.006 14H2.994A3 3 0 010 10.997V3.003A2.997 2.997 0 012.994 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "20",
      "height": "14",
      "viewBox": "0 0 20 14"
    }
  };
});