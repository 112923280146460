define("@ember/runloop/type-tests.ts/debounce.test", ["@ember/runloop", "expect-type"], function (_runloop, _expectType) {
  "use strict";

  // From Docs

  function whoRan() {// Do stuff
  }
  var myContext = {
    name: 'debounce',
    test(_foo, _baz) {}
  };
  (0, _runloop.debounce)(myContext, whoRan, 150); // less than 150ms passes

  (0, _runloop.debounce)(myContext, whoRan, 150);
  (0, _runloop.debounce)(myContext, whoRan, 150, true); // console logs 'debounce ran.' one time immediately.
  // 100ms passes

  (0, _runloop.debounce)(myContext, whoRan, 150, true); // 150ms passes and nothing else is logged to the console and
  // the debouncee is no longer being watched

  (0, _runloop.debounce)(myContext, whoRan, 150, true); // console logs 'debounce ran.' one time immediately.
  // 150ms passes and nothing else is logged to the console and
  // the debouncee is no longer being watched
  // Method only

  (0, _expectType.expectTypeOf)((0, _runloop.debounce)((_foo, _baz) => {}, 1, undefined, 1)).toEqualTypeOf(); // @ts-expect-error Requires wait

  (0, _runloop.debounce)((_foo, _baz) => {}, 1, true); // @ts-expect-error Requires all args

  (0, _runloop.debounce)((_foo, _baz) => {}, 1, 1); // Can set immediate

  (0, _runloop.debounce)((_foo, _baz) => {}, 1, true, 1, true); // With target

  (0, _runloop.debounce)(myContext, function (_foo, _baz) {
    (0, _expectType.expectTypeOf)(this).toEqualTypeOf(myContext);
  }, 1, true, 1, true); // With key

  (0, _runloop.debounce)(myContext, 'test', 1, true, 1, true); // @ts-expect-error invalid key

  (0, _runloop.debounce)(myContext, 'invalid');
  class Foo {
    test(_foo, _bar, _baz) {
      return 1;
    }
  }
  var foo = new Foo(); // With only function

  (0, _expectType.expectTypeOf)((0, _runloop.debounce)((_foo, _bar, _baz) => {
    return 1;
  }, 1, true, undefined, 1)).toEqualTypeOf();
  (0, _expectType.expectTypeOf)((0, _runloop.debounce)((_foo, _bar, _baz) => {
    return 1;
  }, 1, true, 'string', 1)).toEqualTypeOf();
  (0, _runloop.debounce)(_foo => {
    return 1; // @ts-expect-error invalid argument
  }, 'string'); // With target and function

  (0, _expectType.expectTypeOf)((0, _runloop.debounce)(foo, function (_foo, _bar, _baz) {
    (0, _expectType.expectTypeOf)(this).toEqualTypeOf();
    return 1;
  }, 1, true, undefined, 1)).toEqualTypeOf();
  (0, _expectType.expectTypeOf)((0, _runloop.debounce)(foo, function (_foo, _bar, _baz) {
    return 1;
  }, 1, true, 'string', 1)).toEqualTypeOf(); // @ts-expect-error invalid args

  (0, _runloop.debounce)(foo, function (_foo, _bar, _baz) {
    return 1;
  }, 1, 'string', true, 1); // With function string reference

  (0, _expectType.expectTypeOf)((0, _runloop.debounce)(foo, 'test', 1, true, 'string', 1)).toEqualTypeOf();
  (0, _expectType.expectTypeOf)((0, _runloop.debounce)(foo, 'test', 1, true, undefined, 1)).toEqualTypeOf(); // @ts-expect-error Invalid args

  (0, _runloop.debounce)(foo, 'test', 'string');
});