define("@heroku/ember-hk-components/templates/components/hk-slide-panel/content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "halsyfH1",
    "block": "[[[10,0],[14,0,\"flex flex-column h-100\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"slide-panel-shadow-cover-top z-5\"],[12],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"slide-panel-content flex-grow-1 flex-shrink-0 \",[30,0,[\"paddingClass\"]]]]],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"slide-panel-shadow-cover-bottom z-5\"],[12],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@heroku/ember-hk-components/templates/components/hk-slide-panel/content.hbs",
    "isStrictMode": false
  });
});