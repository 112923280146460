define("@heroku/ember-hk-components/components/hk-well/body", ["exports", "@ember/component", "@heroku/ember-hk-components/templates/components/hk-well/body"], function (_exports, _component, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _body.default,
    classNames: ['f5 gray lh-copy'],
    attributeBindings: ['data-test-target'],
    'data-test-target': 'hk-well-body'
  });
});