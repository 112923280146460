define("@heroku/ember-hk-components/components/async-button", ["exports", "@ember/object", "@ember/object/computed", "@ember/component", "dashboard/utils/overridable-computed"], function (_exports, _object, _computed, _component, _overridableComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const positionalParamsMixin = {
    positionalParams: 'params'
  };
  const ButtonComponent = _component.default.extend(positionalParamsMixin, {
    tagName: 'button',
    textState: 'default',
    asyncState: (0, _computed.alias)('default'),
    reset: false,
    classNames: ['async-button'],
    classNameBindings: ['textState'],
    attributeBindings: ['disabled', 'type', '_href:href', 'tabindex', 'data-test-target'],
    type: 'submit',
    disabled: (0, _object.computed)('textState', 'disableWhen', function () {
      const textState = (0, _object.get)(this, 'textState');
      const disableWhen = (0, _object.get)(this, 'disableWhen');
      return disableWhen || textState === 'pending';
    }),
    click() {
      const params = this.params ?? [];
      const callbackHandler = promise => {
        (0, _object.set)(this, 'promise', promise);
      };
      this.action(callbackHandler, ...params);
      (0, _object.set)(this, 'textState', 'pending');

      // If this is part of a form, it will perform an HTML form
      // submission without returning false to prevent action bubbling
      return false;
    },
    ...(0, _overridableComputed.default)('text', (0, _object.computed)('textState', 'default', 'pending', 'resolved', 'fulfilled', 'rejected', function () {
      return this[this.textState] ?? this.default;
    })),
    /* eslint-disable ember/no-observers */
    resetObserver: (0, _object.observer)('textState', 'reset', function () {
      const states = ['resolved', 'rejected', 'fulfilled'];
      let found = false;
      const textState = (0, _object.get)(this, 'textState');
      for (let idx = 0; idx < states.length && !found; idx++) {
        found = textState === states[idx];
      }
      if ((0, _object.get)(this, 'reset') && found) {
        (0, _object.set)(this, 'textState', 'default');
      }
    }),
    handleActionPromise: (0, _object.observer)('promise', function () {
      const promise = (0, _object.get)(this, 'promise');
      if (!promise) {
        return;
      }
      promise.then(() => {
        if (!this.isDestroyed) {
          (0, _object.set)(this, 'textState', 'fulfilled');
        }
      }).catch(() => {
        if (!this.isDestroyed) {
          (0, _object.set)(this, 'textState', 'rejected');
        }
      });
    }),
    /* eslint-enable ember/no-observers */

    setUnknownProperty(key, value) {
      this[key] = null;
      this.set(key, value);
    },
    _href: (0, _object.computed)('href', function () {
      const href = (0, _object.get)(this, 'href');
      if (href) {
        return href;
      }
      const tagName = (0, _object.get)(this, 'tagName').toLowerCase();
      if (tagName === 'a' && href === undefined) {
        return '';
      }
    }),
    _stateObject: (0, _object.computed)('textState', function () {
      const textState = (0, _object.get)(this, 'textState');
      const isFulfilled = textState === 'fulfilled' || textState === 'resolved';
      return {
        isPending: textState === 'pending',
        isFulfilled,
        isResolved: isFulfilled,
        isRejected: textState === 'rejected',
        isDefault: textState === 'default'
      };
    })
  });
  var _default = _exports.default = ButtonComponent;
});