define("ember-svg-jar/inlined/dyno-pl-ram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dyno-pl-ram</title><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.873 1.485a2.374 2.374 0 012.255 0l4.745 2.586C14.57 4.451 15 5.154 15 5.914v5.172c0 .76-.43 1.463-1.127 1.843l-4.745 2.585a2.37 2.37 0 01-2.255 0L2.128 12.93C1.43 12.55 1 11.846 1 11.086V5.914c0-.76.43-1.462 1.128-1.843l4.745-2.586z\" fill=\"url(#paint0_linear_69_960)\"/><path d=\"M5.486 11.604V5.786h1.58v4.545h2.351v1.273H5.486z\" fill=\"#fff\"/><path opacity=\".7\" d=\"M8.286 9.2v-4h1.727c.296 0 .556.054.779.162.223.108.396.264.52.467.123.203.185.447.185.73 0 .287-.064.528-.191.725a1.176 1.176 0 01-.534.445c-.228.1-.494.15-.798.15H8.942v-.843h.813a.998.998 0 00.326-.047.413.413 0 00.209-.154.468.468 0 00.074-.276.481.481 0 00-.074-.279.415.415 0 00-.209-.16.897.897 0 00-.326-.053h-.383V9.2H8.286zm2.344-1.836l1 1.836h-1.18l-.976-1.836h1.156z\" fill=\"#fff\"/><defs><linearGradient id=\"paint0_linear_69_960\" x1=\"-6\" y1=\"8.5\" x2=\"8.587\" y2=\"22.488\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#AB8ECD\"/><stop offset=\"1\" stop-color=\"#79589F\"/></linearGradient></defs>",
    "attrs": {
      "width": "16",
      "height": "17",
      "viewBox": "0 0 16 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});