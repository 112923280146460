define("ember-svg-jar/inlined/cc-visa-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><linearGradient id=\"jdzhdRa\" x1=\"50%\" x2=\"50%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#FFF\"/><stop offset=\"100%\" stop-color=\"#EDEFF9\"/></linearGradient><linearGradient id=\"jdzhdRb\" x1=\"0%\" x2=\"66.866%\" y1=\"0%\" y2=\"100%\"><stop offset=\"0%\" stop-color=\"#191E62\"/><stop offset=\"100%\" stop-color=\"#10289A\"/></linearGradient></defs><g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"22\" height=\"16\" fill=\"url(#jdzhdRa)\" rx=\"3\"/><path fill=\"#000\" fill-opacity=\".101\" fill-rule=\"nonzero\" d=\"M2.994.75A2.247 2.247 0 00.75 3.003v9.994a2.25 2.25 0 002.244 2.253h16.012a2.247 2.247 0 002.244-2.253V3.003A2.25 2.25 0 0019.006.75H2.994zm0-.75h16.012A3 3 0 0122 3.003v9.994A2.997 2.997 0 0119.006 16H2.994A3 3 0 010 12.997V3.003A2.997 2.997 0 012.994 0z\"/><path fill=\"url(#jdzhdRb)\" d=\"M11.21 6.918C11.217 5.788 12.261 5 13.745 5c.578 0 1.043.126 1.404.258l-.263 1.23a2.826 2.826 0 00-1.48-.26c-.435.057-.633.278-.638.486-.014.685 2.11.77 2.104 2.3C14.868 10.223 13.836 11 12.26 11c-.671-.006-1.32-.147-1.669-.308l.272-1.272c.348.163.787.379 1.541.367.433-.007.895-.178.9-.568.001-.254-.195-.437-.78-.721-.569-.278-1.324-.744-1.314-1.58zm7.758-1.812l1.212 5.804h-1.388l-.182-.867h-1.926l-.313.867h-1.576l2.252-5.378a.684.684 0 01.64-.426h1.281zm-1.852 3.747h1.245l-.454-2.18-.79 2.18zM9.3 5.106h1.5L9.56 10.91H8.056l1.242-5.804zm-.645 0L6.222 10.91H4.635L3.438 6.278c-.073-.285-.136-.39-.357-.51-.36-.196-.957-.38-1.481-.493l.036-.169H4.19c.326 0 .619.216.693.591l.632 3.36 1.563-3.951h1.576z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "22",
      "height": "16",
      "viewBox": "0 0 22 16"
    }
  };
});