define("ember-svg-jar/inlined/dyno-shield-pl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>dyno-shield-pl</title><g clip-path=\"url(#clip0_227_48)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.5 0l3.277.86c.134.03.223.15.223.283-.015 2.279-1.236 4.394-3.336 5.809a.3.3 0 01-.164.044.3.3 0 01-.164-.044C10.236 5.537 9.015 3.422 9 1.143c0-.134.09-.253.223-.283 0 0 3.232-.875 3.277-.86zm-.022 8.335c-2.7-1.819-4.319-4.5-4.469-7.41a2.376 2.376 0 00-2.136.06L1.128 3.57C.43 3.951 0 4.654 0 5.414v5.172c0 .76.43 1.463 1.128 1.843l4.745 2.585a2.37 2.37 0 002.255 0l4.745-2.585C13.57 12.05 14 11.346 14 10.586V7.511c-.338.291-.7.566-1.082.824-.06.04-.14.06-.22.06-.08 0-.16-.02-.22-.06z\" fill=\"url(#paint0_linear_227_48)\"/><mask style=\"mask-type:luminance\" maskUnits=\"userSpaceOnUse\" x=\"7\" y=\"-1\" width=\"11\" height=\"10\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.698-1l4.4 1.155c.18.04.3.2.3.38-.02 3.06-1.66 5.9-4.48 7.8-.06.04-.14.06-.22.06-.08 0-.16-.02-.22-.06-2.82-1.9-4.46-4.74-4.48-7.8 0-.18.12-.34.3-.38 0 0 4.34-1.175 4.4-1.155z\" fill=\"#fff\"/></mask><path d=\"M5.435 10.734V4.916h1.58v4.545h2.352v1.273H5.435z\" fill=\"url(#paint1_linear_227_48)\"/></g><defs><linearGradient id=\"paint0_linear_227_48\" x1=\"-8\" y1=\"7.65\" x2=\"7.285\" y2=\"23.634\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#8EBDF1\"/><stop offset=\"1\" stop-color=\"#006DEB\"/></linearGradient><linearGradient id=\"paint1_linear_227_48\" x1=\"2.564\" y1=\"7.734\" x2=\"10.556\" y2=\"11.724\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#fff\"/><stop offset=\"1\" stop-color=\"#fff\"/></linearGradient><clipPath id=\"clip0_227_48\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});