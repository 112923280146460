define("ember-svg-jar/inlined/icon--feature-autoscaling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Combined Shape</title><defs><linearGradient x1=\"0%\" y1=\"0%\" x2=\"100%\" y2=\"100%\" id=\"djyfiva\"><stop stop-color=\"#AC8ECE\" offset=\"0%\"/><stop stop-color=\"#79589F\" offset=\"100%\"/></linearGradient></defs><path d=\"M83.267 50.002h-2.813c-.397 0-.747.281-.858.69l-.703 1.979-1.841-9.797c-.084-.442-.444-.761-.876-.767-.417 0-.78.31-.87.748l-1.55 12.225-2.275-15.275c-.065-.43-.395-.763-.802-.8-.398-.044-.785.22-.921.634l-2.155 10.368H64.98c-.492 0-.891.428-.891.958 0 .528.399.955.89.955h3.254c.379 0 .715-.256.841-.64l1.2-7.45 2.454 16.482a.921.921 0 00.88.804c.416 0 .778-.309.871-.749l1.652-12.708 1.734 9.223c.08.431.427.747.837.765.398.04.78-.267.895-.69l1.527-5.042h2.096a2 2 0 003.752-.586 1.013 1.013 0 00-.032-.815 2 2 0 00-3.674-.512zM57 44H37.996A6.003 6.003 0 0032 50c0 3.307 2.685 6 5.996 6H57v.731c0 1.977 1.105 3.806 2.9 4.797L72.1 68.257a6.03 6.03 0 005.8 0l12.202-6.729c1.792-.99 2.897-2.82 2.897-4.797V56h19.004A6.003 6.003 0 00118 50c0-3.307-2.685-6-5.996-6H93v-.73c0-1.98-1.105-3.807-2.897-4.798L77.9 31.742a6.041 6.041 0 00-5.8 0L59.9 38.471c-1.795.991-2.9 2.818-2.9 4.797V44zm0 2H37.997a4.001 4.001 0 000 8H57v-8zm36 0h19.003a4.001 4.001 0 010 8H93v-8zm-32.115-5.802C59.714 40.842 59 42.02 59 43.289v13.422c0 1.269.715 2.448 1.885 3.092l12.202 6.708a3.994 3.994 0 003.829 0l12.2-6.708C90.286 59.16 91 57.98 91 56.711V43.289c0-1.27-.714-2.447-1.883-3.091l-12.2-6.71a4.005 4.005 0 00-3.83-.001l-12.202 6.71zM49 46h2v8h-2v-8zm-8 0h2v8h-2v-8zm58 0h2v8h-2v-8zm8 0h2v8h-2v-8z\" transform=\"translate(-32 -31)\" fill=\"url(#djyfiva)\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "86",
      "height": "38",
      "viewBox": "0 0 86 38",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});