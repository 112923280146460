define("torii/lib/load-initializer", ["exports", "@ember/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(initializer) {
    (0, _application.onLoad)('Ember.Application', function (Application) {
      Application.initializer(initializer);
    });
  }
});