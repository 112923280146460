define("torii/lib/popup-id-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PopupIdSerializer = {
    serialize(popupId) {
      return 'torii-popup:' + popupId;
    },
    deserialize(serializedPopupId) {
      if (!serializedPopupId) {
        return null;
      }
      var match = serializedPopupId.match(/^(torii-popup:)(.*)/);
      return match ? match[2] : null;
    }
  };
  var _default = _exports.default = PopupIdSerializer;
});